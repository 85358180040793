import axios from "axios"

class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: "https://api.pickgeo.com/api/geoapi/",
        })
    }

    getCensus(latlng) {

        return this.api.post(`/padron?access_type=latlon_radius&token=${process.env.VUE_APP_API_TOKEN}`, {
            lat: `${latlng.lat}`,
            lon: `${latlng.lng}`,
            radius: '500',
            data_mode: 'distributed'
        })

    }

    getRent(latlng) {
        return this.api.post(`/renta?access_type=latlon_radius&token=${process.env.VUE_APP_API_TOKEN}`, {
            lat: `${latlng.lat}`,
            lon: `${latlng.lng}`,
            radius: '500',
            data_mode: 'distributed'
        })
    }

    getMarkers(latlng) {
        return this.api.post(`/markers?access_type=latlon_radius&token=${process.env.VUE_APP_API_TOKEN}`, {
            lat: `${latlng.lat}`,
            lon: `${latlng.lng}`,
            radius: '500',
            data_mode: 'distributed'
        })
    }

}

const apiService = new ApiService()

export default apiService