const getMarkersTags = (data) => {

    const markersTags = data.map(elm => elm.name)

    const newArray = Object.values(markersTags)

    return newArray

}


export default getMarkersTags