<template>
    <section class="icons-section">
        <article :class="{ 'no-margin': isFirstArticle }">
            <img :src="`/${this.img}.svg`" :alt="this.img">
            <h4>{{ this.title }}</h4>
            <h5> {{ this.text }}</h5>
        </article>
    </section>
</template>


<script>
export default {
    name: 'IconCardInfo',
    props: {
        isFirstArticle: Boolean,
        img: String,
        title: String,
        text: String
    }
}
</script>


<style scoped>
h4,
h5,
img {
    text-align: start;
    margin: 0px;
}

img {
    display: block;
    width: 44px;
    margin-bottom: 15px;
}

h5 {
    margin-bottom: 20px;
}


@media (min-width: 834px) {
    .icons-section {
        display: flex;
    }

    .flex article {
        margin-left: 20px;
    }

    .no-margin {
        margin-top: 0;
        margin-left: 0 !important;
    }

}
</style>
