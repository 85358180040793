<template>
    <div class="container">

        <label :for="this.id" :class="this.disabled ? 'disabled' : ''">{{ this.title }}</label>

        <textarea :name="this.id" :id="this.id" cols="10" rows="2" :placeholder="this.placeholder" :disabled="this.disabled"
            class="form-input" @input="$emit('input-change', $event.target.value, this.id)"></textarea>

    </div>
</template>


<script>
import { mapState } from 'vuex'
export default {
    name: 'TextArea',
    props: {
        title: String,
        placeholder: String,
        id: String,
        disabled: Boolean
    },
    computed: {
        ...mapState(['address'])
    }
}
</script>


<style scoped>
.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
}

label {
    font-weight: 700;
    text-align: start;
    margin: 36px 0 0;
}

.disabled {
    color: #9B9B9B;
}

textarea {
    display: block;
    margin: 8px 0;
    padding: 12px 16px;
    width: 100%;
    outline: none;
    border: 1px solid black;
    background-color: white;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    resize: vertical;
    overflow: auto;
}

textarea:disabled {
    border: 1px solid #9B9B9B;
    resize: none;
}
</style>