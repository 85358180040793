import axios from "axios"

class BackendService {
    constructor() {
        this.api = axios.create({
            baseURL: "https://ywz6kcf1jf.execute-api.eu-west-1.amazonaws.com/staging",
        })
    }

    sendEmail(data) {
        return this.api.post('/email', data)
    }

}

const backendService = new BackendService()

export default backendService