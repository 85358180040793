<template>
    <div class="footer-container">

        <img src="/Logo-PromocionEconomica.svg" alt="">

        <div class="footer-content">

            <article>
                <p>Oficina de Promoción Económica</p>
                <ul>
                    <li>923 28 14 14</li>
                    <li>Plaza Mayor nº 15 2ª planta 37002 - Salamanca (Salamanca)</li>
                    <li><a href="mailto:promocioneconomica@aytosalamanca.es">promocioneconomica@aytosalamanca.es</a>
                    </li>
                </ul>
            </article>

            <article>
                <p>Recursos útiles</p>
                <ul>
                    <li><a href="https://www.aytosalamanca.gob.es/es/tramitesgestiones/todostramites/index.html">Licencias
                            y permisos</a></li>
                    <li><a href="https://www.aytosalamanca.gob.es/es/tramitesgestiones/todostramites/index.html">Ayudas
                            y subvenciones</a></li>
                    <li><a href="https://www.aytosalamanca.gob.es/es/tramitesgestiones/todostramites/index.html">Servicios
                            Municipales</a></li>
                    <!-- <li><a href="#">Preguntas Frecuentes</a></li> -->
                    <li><a href="http://empresasyemprendedores.aytosalamanca.es/es/contacto/index.html">Contacto</a>
                    </li>
                </ul>
            </article>

            <article>
                <p>Explora más</p>
                <ul>
                    <li><a href="https://www.aytosalamanca.gob.es/es/tramitesgestiones/todostramites/index.html">Empleo
                            y oportunidad</a></li>
                    <li><a href="https://indicadores.usal.es/portal/">Indicadores USAL</a></li>
                    <li><a href="https://www.mastormessalamanca.es/">MasTormes Salamanca</a></li>
                    <li><a href="https://www.dyntra.org/poi/ayuntamiento-de-salamanca/">DYNTRA</a></li>
                </ul>
            </article>

            <article>
                <p>Otros Servicios</p>
                <ul>
                    <li><a
                            href="http://empresasyemprendedores.aytosalamanca.es/es/proyectosestrategicos/abioinnova/">ABIOINNOVA</a>
                    </li>
                    <li><a href="#">Centro Tormes Plus</a></li>
                    <li><a href="http://empresasyemprendedores.aytosalamanca.es/es/canalempresa/auladigital/">Aula
                            Digital
                            de Formación</a></li>
                    <li><a
                            href="http://empresasyemprendedores.aytosalamanca.es/es/comercioymercados/appactivasalamanca/">SALdeCompras</a>
                    </li>
                </ul>
            </article>

        </div>

        <hr class="footer-hr">

        <div class="footer-bottom">

            <h5>© 2023 Ayuntamiento de Salamanca</h5>

            <div class="flex">
                <h5><a href="http://www.aytosalamanca.es/es/politicaprivacidad/index.html">Política de privacidad</a>
                </h5>
                <h5><a href="http://www.aytosalamanca.es/es/notalegal/index.html">Nota legal</a></h5>
            </div>

            <div class="footer-logos">
                <a href="#"><i class="fab fa-facebook-square"></i></a>
                <a href="#"><i class="fab fa-youtube"></i></a>
                <a href="#"><i class="fab fa-flickr"></i></a>
                <a href="#"><img src="../../public/x.svg" alt=""></a>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    name: 'Footer'
}
</script>


<style scoped>
.footer-container {
    width: calc(100% - 80px);
    margin: 0 auto;
    text-align: start;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
}

img {
    display: block;
    width: 70%;
    height: auto;
    margin: auto;
}

p {
    margin-top: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 16px;
    font-size: 14px;
    width: fit-content;
}

.footer-content a {
    text-decoration: none;
    color: var(--base-900);
    font-size: 14px;
}

.footer-hr {
    border: 1px solid var(--base-900);
    margin: 28px 0;
    width: 100%;
}

h5 {
    margin: 0 0 12px 0;
    text-align: center;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: black;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.footer-logos {
    width: 40%;
    margin: 16px auto 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-logos i {
    color: var(--base-900);
    font-size: 16px;
    margin: 0 6px;
}

.footer-logos img {
    display: inline;
    width: 18px;
    height: 100%;
    margin: 0 6px;
}

@media (min-width: 834px) {
    .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    img {
        width: 45%;
    }

    .footer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    article:not(:first-child) {
        width: 30%;
    }

    article:first-child {
        width: 80%;
    }

    .footer-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    h5 {
        margin-left: 0;
        margin-right: 18px;
        margin-bottom: 0px;
        display: inline;
    }

    .flex {
        display: inline;
        justify-content: space-between;
        align-items: center;
    }

    .flex h5 {
        margin: 0 18px;
    }

    .footer-logos {
        margin: 0;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-left: auto;
    }
}

@media (min-width: 1280px) {
    .footer-container {
        margin-left: 68px;
        margin-right: 48px;
        max-width: calc(100% - 116px);
        width: auto;
    }

    img {
        width: 270px;
        margin-bottom: 40px;
    }

    .footer-content {
        display: flex;
        width: 100%;
    }

    article:not(:first-child) {
        width: 15%;
    }

    article:first-child {
        width: 25%;
    }

    article {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 20px;
    }
}
</style>
