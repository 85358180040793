<template>
    <div>

        <vue-google-autocomplete id="search-bar" ref="autocomplete" classname="form-control" placeholder="Buscar dirección"
            @placechanged="getAddressData" :country="['es']">
        </vue-google-autocomplete>

        <img src="/Search.svg" alt="search">

    </div>
</template>


<script>
import { mapActions } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'


export default {
    name: 'SearchBar',
    components: {
        VueGoogleAutocomplete
    },
    methods: {
        ...mapActions(['setSelectedLngLat']),
        getAddressData(addressData) {

            const selectedLngLat = {
                lng: addressData.longitude,
                lat: addressData.latitude
            }
            this.setSelectedLngLat(selectedLngLat)

        }
    },
}
</script>


<style scoped>
div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 280px;
    padding: 12px 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    background-color: white;
    position: relative;
    z-index: 1;
    margin: 20px 0 -60px 45px;
}

#search-bar {
    display: flex;
    position: relative;
    border: none;
    z-index: 1;
    outline: white;
    width: 100%;
}

img {
    width: 24px;
}

@media (min-width: 834px) {
    div {
        margin: 20px 0 -160px 60px;
    }
}

@media (min-width: 1280px) {
    div {
        width: 340px;
        margin: 20px 0 -160px 80px;
    }
}
</style>