<template>
    <div class="no-direction-warning-container">

        <h2>Upsss nos falta una dirección</h2>

        <p>Todavía no has seleccionado una dirección sobre la que podamos darte los datos que buscas, puedes seguir los
            siguientes pasos:</p>

        <ul>
            <li>
                <img src="/Badge Check.svg" alt="list element">
                <h5>Busque o seleccione sobre el mapa la dirección que está interesado.</h5>
            </li>
            <li>
                <img src="/Badge Check.svg" alt="list element">
                <h5>Compruebe que la dirección es correcta en el espacio inferior del mapa.</h5>
            </li>
        </ul>

    </div>
</template>


<script>
export default {
    name: 'NoDirectionWarning'
}
</script>


<style scoped>
.no-direction-warning-container {
    padding: 40px 24px;
}

h2 {
    margin: 0;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    display: flex;
    gap: 12px;
    margin: 8px;
}

h5 {
    margin: 0;
}


@media (min-width: 834px) {
    .no-direction-warning-container {
        padding: 40px;
    }
}


@media (min-width: 1280px) {
    .no-direction-warning-container {
        padding: 40px 68px;
    }
}
</style>