<template>
    <div class="form-item">

        <label :for="this.id">{{ this.title }}</label>

        <select :id="this.id" :name="this.id" @input="$emit('input-change', $event.target.value, this.id)">

            <option :value="this.placeholder" selected hidden>{{ this.placeholder }}</option>
            <option v-for="(option, index) in options" :value="option" :key="index">
                {{ option }}
            </option>

        </select>

    </div>
</template>


<script>
export default {
    name: 'SelectInput',
    props: {
        title: String,
        placeholder: String,
        id: String,
        options: Array
    }
}
</script>


<style scoped>
.form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 36px;
}

label {
    font-weight: 700;
    text-align: start;
}

select {
    display: flex;
    margin: 8px 0;
    padding: 12px 16px;
    width: 100%;
    outline: none;
    border: 1px solid #161616;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
}

select:not(:checked) {
    color: gray;
}


@media (min-width: 834px) {
    .form-item {
        width: 275px
    }
}

@media (min-width: 1280px) {
    .form-item {
        width: 376px
    }
}
</style>