<template>
    <div class="form-item">

        <label :for="this.id">{{ this.title }}</label>

        <input :type="this.type" :id="this.id" :name="this.id" :placeholder="this.placeholder" :disabled="this.disabled"
            class="form-input" @input="$emit('input-change', $event.target.value, this.id)">

    </div>
</template>


<script>
export default {
    name: 'Input',
    props: {
        title: String,
        placeholder: String,
        id: String,
        type: String,
        disabled: Boolean
    }
}
</script>


<style scoped>
.form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 36px;
}

label {
    font-weight: 700;
    text-align: start;
}

input {
    display: flex;
    margin: 8px 0;
    padding: 12px 16px;
    width: 100%;
    outline: none;
    border: 1px solid #161616;
    font-size: 16px;
}

@media (min-width: 834px) {
    .form-item {
        width: 275px
    }
}

@media (min-width: 1280px) {
    .form-item {
        width: 376px
    }
}
</style>