<template>
    <div class="container">

        <img :src="this.isValid ? '/Check Circle.svg' : '/Exclamation Triangle.svg'"
            :alt="this.isValid ? `${this.id} válido` : `${this.id} no válido`"
            :class="this.isValid ? 'valid' : 'not-valid'">

        <h5 :class="this.isValid ? 'valid' : 'not-valid'">{{ this.isValid ? `${this.id} válido` : `${this.id} no válido` }}
        </h5>

    </div>
</template>


<script>
export default {
    name: 'IconValidation',
    props: {
        isValid: Boolean,
        id: String
    }
}
</script>


<style scoped>
.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

img {
    width: 24px;
}

h5 {
    margin: 0;
}

.valid {
    filter: brightness(0) saturate(100%) invert(32%) sepia(98%) saturate(1206%) hue-rotate(113deg) brightness(92%) contrast(106%);
    color: #049625;
}

.not-valid {
    filter: brightness(0) saturate(100%) invert(9%) sepia(90%) saturate(7009%) hue-rotate(18deg) brightness(103%) contrast(107%);
    color: #d00f08;
}
</style>