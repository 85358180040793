<template>
    <div class="container chart" v-if="this.areaData.population != 0 && this.areaData.malePopulationByAge.length">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-if="this.areaData.population == 0 || !this.areaData.malePopulationByAge.length">
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>¡No se pueden mostrar los datos!</h5>
            <h6>No existen suficientes personas censadas en la zona seleccionada. <strong> Por favor seleccione otra
                    zona para mostrar los datos.</strong></h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'

export default {
    name: 'ChartTwoBars',
    computed: {
        ...mapState(['areaData'])
    },
    data() {
        return {
            series: [{
                name: 'Hombres',
                data: this.areaData?.malePopulationByAge
            },
            {
                name: 'Mujeres',
                data: this.areaData?.femalePopulationByAge
            }
            ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 440,
                    stacked: true
                },
                colors: ['#616666', '#c6a94b'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '80%',
                    },
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                tooltip: {
                    shared: false,
                    x: {
                        formatter: function (val) {
                            return val
                        }
                    },
                    y: {
                        formatter: function (val) {
                            return Math.abs(val)
                        }
                    }
                },
                xaxis: {
                    categories: ['+100', '95-99', '90-94', '85-89', '80-84', '75-79', '70-74', '65-69', '60-64', '55-59', '50-54', '45-49', '40-44', '35-39', '30-34', '25-29', '20-24', '15-19', '10-14', '5-9', '0-4'],
                    title: {
                        text: 'Población'
                    },
                    labels: {
                        formatter: function (val) {
                            return Math.abs(Math.round(val))
                        }
                    }
                },
            },
        };
    },
    mounted() {

        this.series[0].data = this.areaData.malePopulationByAge
        this.series[1].data = this.areaData.femalePopulationByAge

    }
}
</script>


<style>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>