<template>
    <div class="form-item">
        <label :for="this.id">{{ this.title }}</label>
        <n-select v-model:value="selectedOption" size="large" :options="options" placeholder="Seleccionar negocio"
            class="select-input" style="text-align: start;" :theme-overrides="{
                peers: {
                    InternalSelection: {
                        textColor: 'black',
                        textAlign: 'start',
                        fontSizeLarge: '16px',
                        heightLarge: '45px',
                        border: '1px solid #000',
                        borderRadius: '0px',
                        placeholderColor: 'grey',
                        borderActive: 'none',
                        borderFocus: 'none',
                        borderHover: 'none',
                        boxShadowActive: 'none',
                        boxShadowFocus: 'none',
                        arrowColor: 'black'

                    },
                    InternalSelectMenu: {
                        optionCheckColor: '#c6a94b',
                        optionTextColorActive: '#c6a94b',
                        optionTextColorPressed: '#c6a94b',
                    }
                }
            }" />
    </div>
</template>


<script>
import { NSelect } from "naive-ui"

export default {
    components: {
        NSelect,
    },
    name: 'NaiveSelectInput',
    props: {
        title: String,
        options: Array,
        id: String
    },
    data() {
        return {
            selectedOption: null
        }
    },
    watch: {
        selectedOption() {
            this.$emit('input-change', this.selectedOption, this.id)
        }
    }
}
</script>


<style scoped>
.form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 36px;
}

label {
    font-weight: 700;
    text-align: start;
    margin-bottom: 8px;
}

.select-input:focus {
    outline: none;
}

@media (min-width: 834px) {
    .form-item {
        width: 275px
    }
}

@media (min-width: 1280px) {
    .form-item {
        width: 376px
    }
}
</style>