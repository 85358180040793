<template>
    <div class="flex">

        <div class="chart-card-container">
            <p>Población por edad</p>
            <ChartTwoBars />
        </div>

        <div class="chart-card-container">
            <p>Comercios por sector</p>
            <ChartBars />
        </div>

    </div>
</template>


<script>
import ChartTwoBars from './Charts/ChartTwoBars.vue'
import ChartBars from './Charts/ChartBars.vue'

export default {
    name: 'ChartCards',
    components: {
        ChartTwoBars,
        ChartBars
    }
}
</script>


<style scoped>
.chart-card-container {
    margin-bottom: 20px;
    padding: 28px 44px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
}

.flex {
    padding-top: 20px;
}

@media (min-width: 1280px) {
    .flex {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0;
        gap: 20px;
    }
}
</style>