const getMarkersValues = (data) => {

    const markersValues = data.map(elm => elm.count)

    const newArray = Object.values(markersValues)

    return newArray

}


export default getMarkersValues