const formatPopulationByAge = (data, type) => {

    const dataKeys = Object.keys(data)

    let formattedData = []

    dataKeys.forEach(elm => formattedData.push(data[elm]))

    const isEmpty = formattedData.filter(elm => elm != 0)

    if (isEmpty.length) {

        if (type === 'female') {
            let femaleArr = formattedData.map(elm => -elm)

            return femaleArr.reverse()
        }
        else return formattedData.reverse()

    }
    else return isEmpty

}


export default formatPopulationByAge