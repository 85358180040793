<template>
    <div :class="{ 'info-card-container': !isBig, 'big': isBig }">
        <img :src="`/${this.img}.png`" :alt="this.img">

        <div class="text-container">
            <h5>{{ this.detail }}</h5>

            <h4>{{ this.title }}</h4>

            <h5>{{ this.text }}</h5>

            <Button class="btn" @click="handleShowForm" buttonText="Solicitar" />
        </div>
    </div>
</template>


<script>
import { mapActions, mapState } from 'vuex'
import { useMessage } from "naive-ui"
import Button from './Button.vue'


export default {
    name: 'CommunicationCardContent',
    components: {
        Button
    },
    props: {
        isBig: Boolean,
        img: String,
        detail: String,
        title: String,
        text: String
    },
    computed: {
        ...mapState(['address'])
    },
    data() {
        return {
            message: useMessage()
        }
    },
    methods: {
        ...mapActions(['setShowForm', 'setFormType', 'setShowAlert']),
        handleShowForm() {
            if (this.address) {
                this.setShowForm(true)
                this.setFormType(this.title)
            }
            else {
                this.message.error("Selecciona una dirección para poder iniciar este trámite", { duration: 3000 })
                this.setShowAlert(true)

                setTimeout(() => {
                    this.setShowAlert(false)
                }, "3000")
            }
        }
    }
}
</script>


<style scoped>
.info-card-container {
    padding-bottom: 24px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
}

img {
    width: 100%;
    height: auto;
    margin-bottom: 24px;
}

.text-container {
    padding: 0 24px;
}

h4 {
    margin: 8px 0 16px 0;
}

h5 {
    margin: 0;
}

.btn {
    margin-top: 20px;
}




@media (min-width: 834px) {
    .info-card-container {
        min-width: 355px;
        height: auto;
        max-width: 45%;
    }

    .big {
        display: flex;
        flex-direction: row;
        width: 100%;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
        padding-bottom: 0;
    }

    .big img {
        width: 367px;
        margin-bottom: 0;
    }

    .big .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media (min-width: 1280px) {
    .info-card-container {
        height: auto;
        max-width: 30%;
    }
}
</style>