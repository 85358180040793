<template>
    <h5>{{ this.id }} es requerido</h5>
</template>


<script>
export default {
    name: 'SimpleValidation',
    props: {
        id: String
    }
}
</script>


<style scoped>
h5 {
    margin: 0;
    color: #d00f08;
    text-align: start;
}
</style>