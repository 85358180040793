const getTradeMarkers = (data) => {

    const filteredArray = data.filter(elm => {
        return elm.category === 'Comercio'
    })

    return filteredArray

}


export default getTradeMarkers