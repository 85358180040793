<template>
    <form ref="contactForm" id="contactForm" class="form-container" @submit.prevent="handleValidateClick" @click.stop>
        <div class="header">
            <p>Solicitar {{ this.formType }}</p>
            <h2>Formulario de contacto</h2>
            <i class="fas fa-times" @click="closeModal"></i>
        </div>

        <div class="flex-container">
            <span>
                <Input title="Nombre" placeholder="Nombre" id="name" type="text" :disabled="false"
                    @input-change="handleInputChange" @click="validateInput('name')" />
                <SimpleValidation v-if="this.formValid.name == false" id="Nombre" />
            </span>

            <span>
                <NaiveSelectInput :options="optionLabels" title="Tipo de negocio" id="business"
                    @input-change="handleInputChange" @click="validateInput('business')" />
                <SimpleValidation v-if="this.formValid.business == false" id="Tipo de negocio" />
            </span>

            <span>
                <Input title="Teléfono" placeholder="Teléfono" id="phone" type="tel" :disabled="false"
                    @input-change="handleInputChange" />
                <IconValidation v-if="this.formValid.phone != null" :isValid="this.formValid.phone" id="Teléfono" />
            </span>

            <span>
                <Input title="Correo electrónico" placeholder="Correo electrónico" id="email" type="email"
                    :disabled="false" @input-change="handleInputChange" />
                <IconValidation v-if="this.formValid.email != null" :isValid="this.formValid.email" id="Email" />
            </span>

        </div>

        <TextArea title="Dirección" :placeholder="this.address" id="address" type="text" :disabled="true"
            class="text-area" />

        <TextArea title="Comentarios" placeholder="Escriba aquí sus comentarios" id="comments" :disabled="false"
            @input-change="handleInputChange" class="text-area" />

        <vue-recaptcha v-show="true" sitekey="6LfhA84pAAAAAJhdEQe_H6wgP9Dd5x0V54FWic26" size="normal" theme="light"
            hl="es" :loading-timeout="30000" @verify="onCaptchaVerified" @expire="recaptchaExpired"
            @fail="recaptchaFailed" @error="recaptchaError" ref="vueRecaptcha" class="captcha">
        </vue-recaptcha>

        <Button v-if="recaptchaToken" @click="handleValidateClick" buttonText="Enviar"
            :disabled="!this.isValid || !this.recaptchaToken" />
    </form>
</template>


<script>
import { mapActions, mapState } from "vuex"
import { useMessage, NSelect } from "naive-ui"
import Input from "./Inputs/Input.vue"
import SimpleValidation from './Inputs/SimpleValidation.vue'
import SelectInput from "./Inputs/SelectInput.vue"
import NaiveSelectInput from "./Inputs/NaiveSelectInput.vue"
import IconValidation from "./Inputs/IconValidation.vue"
import TextArea from "./Inputs/TextArea.vue"
import Button from "./Button.vue"
import vueRecaptcha from 'vue3-recaptcha2'
import backendService from "../services/backend.service"

export default {
    name: "Form",
    components: {
        Input,
        NSelect,
        useMessage,
        SimpleValidation,
        SelectInput,
        IconValidation,
        TextArea,
        Button,
        NaiveSelectInput,
        vueRecaptcha
    },
    computed: {
        ...mapState(['address', 'formType'])
    },
    data() {
        return {
            selectedOption: null,
            optionLabels: [
                { label: "Hostelería", value: "Hostelería" },
                { label: "Ocio", value: "Ocio" },
                { label: "Consumo", value: "Consumo" },
                { label: "Trabajo", value: "Trabajo" },
                { label: "Educación", value: "Educación" }
            ],
            formValue: {
                name: '',
                business: '',
                phone: '',
                email: '',
                address: '',
                comments: ''
            },
            formValid: {
                name: null,
                business: null,
                phone: null,
                email: null,
                address: true,
                comments: true
            },
            isValid: false,
            message: useMessage(),
            recaptchaToken: null
        }
    },
    methods: {
        ...mapActions(['setShowForm', 'setShowAlert']),
        closeModal() {
            this.setShowForm(false)
        },
        formatFormData() {
            return {
                name: this.formValue.name,
                business: this.formValue.business,
                phone: this.formValue.phone,
                email: this.formValue.email,
                address: this.formValue.address,
                comments: this.formValue.comments,
                service_requested: this.formType
            }
        },

        handleInputChange(value, id) {

            this.formValue[id] = value
            this.validateInput(id)
        },

        handleValidateClick() {
            if (this.validateForm() && this.recaptchaToken) {
                const formData = this.formatFormData()
                backendService
                    .sendEmail(formData)
                    .then(response => console.log(response))
                    .catch(err => console.log(err))

                this.message.success("¡Estupendo! La información se ha enviado correctamente", { duration: 3000 })
                this.setShowAlert(true)

                setTimeout(() => {
                    this.setShowAlert(false)
                }, "3000")
            } else {
                console.log('Formulario inválido')
            }
            this.setShowForm(false)
        },
        validateForm() {
            let errorMsg = ''
            console.log(this.formValue)

            if (!this.formValue.name) {
                this.isValid = false
                errorMsg += 'Nombre es requerido.\n'
            }
            else if (!this.formValue.business) {
                this.isValid = false
                errorMsg += 'Tipo de negocio es requerido.\n'
            }
            else if (!this.formValue.phone.match(/^(?:(?:6|7|8|9)\d{8})$/)) {
                this.isValid = false
                errorMsg += 'Teléfono no válido.\n'
            }
            else if (!this.formValue.email.match(/\S+@\S+\.\S+/)) {
                this.isValid = false
                errorMsg += 'Correo electrónico no válido.\n'
            }
            else if (!this.formValue.address) {
                this.isValid = false
                errorMsg += 'Dirección es requerida.\n'
            }
            else this.isValid = true

            if (!this.isValid) {
                console.error('Errores de validación:', errorMsg)
            }

            return this.isValid
        },
        validateInput(id) {

            if (id == 'name') {
                this.formValue.name ? this.formValid.name = true : this.formValid.name = false
            }
            if (id == 'business') {
                this.formValue.business ? this.formValid.business = true : this.formValid.business = false
            }

            if (id == 'phone') {
                this.formValue.phone.match(/^(?:(?:6|7|8|9)\d{8})$/) ? this.formValid.phone = true : this.formValid.phone = false
            }
            if (id == 'email') {
                this.formValue.email.match(/\S+@\S+\.\S+/) ? this.formValid.email = true : this.formValid.email = false
            }
        },
        handleClickOutside(event) {

            if (this.$refs.contactForm && this.$refs.contactForm?.contains(event.target)) {
                this.setShowForm(true)

            } else if (event.target.tagName.toLowerCase() !== 'button' && !event.target.classList.contains('n-base-select-option') && !event.target.classList.contains('form-input') & !event.target.classList.contains('n-base-selection-label')) {
                this.setShowForm(false)
            }

        },
        onCaptchaVerified(token) {
            this.recaptchaToken = token
        },
        recaptchaExpired() {
            this.$refs.vueRecaptcha.reset()
        },
        recaptchaFailed() {
        },
        recaptchaError(reason) {
            console.log(`Failed to validate reCAPTCHA with error: ${reason}`)
        }
    },
    watch: {
        formValid: {
            handler() {
                this.validateForm()
            },
            deep: true
        }
    },
    mounted() {
        this.formValue.address = this.address

        document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside)
    }

}
</script>



<style scoped>
.form-container {
    width: 327px;
    max-width: 600px;
    margin: 48px auto;
    padding: 48px 26px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
    z-index: 3;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: auto;
    max-height: 90vh;
    align-items: start;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: start;
}

.header p,
h2 {
    margin: 0;
}

h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.fa-times {
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
    font-size: 20px;
    color: var(--base-color-900, #161616);
}

Text .select-input select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
}

.text-area {
    align-content: start;
}

.captcha {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}


@media (min-width: 834px) {
    .form-container {
        width: 659px;
        max-width: unset;
    }

    .flex-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* Defines a 2-column layout */
        gap: 10px;
        /* Optional: Adjusts the gap between grid items */
    }
}

@media (min-width: 1280px) {
    .form-container {
        width: 861px;
    }
}
</style>