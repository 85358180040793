const getCircularArea = (selectedLngLat) => {

    const metresValue = 500
    const points = []
    const numPoints = 64
    const distanceX = (metresValue / 1000) / (111.32 * Math.cos((selectedLngLat.lat * Math.PI) / 180))
    const distanceY = (metresValue / 1000) / 110.574

    let x, y

    for (let i = 0; i < numPoints; i++) {
        const angle = (i * Math.PI * 2) / numPoints
        x = distanceX * Math.cos(angle)
        y = distanceY * Math.sin(angle)

        points.push([selectedLngLat.lng + x, selectedLngLat.lat + y])
    }
    points.push(points[0])

    const polygonInfo = {
        "type": 'FeatureCollection',
        "features": [
            {
                "type": 'Feature',
                "geometry": {
                    "type": 'Polygon',
                    "coordinates": [points]
                }
            }
        ]
    }

    return polygonInfo

}


export default getCircularArea