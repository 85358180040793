<template>
    <span>
        <div class="map-container">

            <SearchBar />
            <MapBox />
            <AddressBox />

            <h2 class="h2-container">Demografía a 500m</h2>

            <div class="spinner"
                v-if="(this.areaData.population === null || this.areaData.rent === null || !this.areaData.malePopulationByAge || !this.areaData.markers) && !this.failedReport">
            </div>
            <p v-if="this.failedReport" class="warning-msg">No hay datos para la ubicación seleccionada. Por favor,
                pruebe con otra ubicación.</p>
            <div class="card-container"
                v-if="this.areaData.population != null && this.areaData.rent != null && this.areaData.malePopulationByAge && this.areaData.markers">
                <DataCard />
                <ChartCards />
            </div>

            <NoDirectionWarning v-if="!this.selectedLngLat" />

            <CommunicationCards />

        </div>

        <Form v-if="this.showForm" />

    </span>
</template>


<script>
import { mapState } from 'vuex'
import { NSpace, NSpin } from "naive-ui"
import SearchBar from '../components/SearchBar.vue'
import MapBox from '../components/MapBox.vue'
import AddressBox from '../components/AddressBox.vue'
import DataCard from '../components/DataCard.vue'
import ChartCards from '../components/ChartCards.vue'
import NoDirectionWarning from '../components/NoDirectionWarning.vue'
import CommunicationCards from '../components/CommunicationCards.vue'
import Form from '../components/Form.vue'

export default {
    name: 'MapPage',
    components: {
        MapBox,
        SearchBar,
        AddressBox,
        NoDirectionWarning,
        CommunicationCards,
        DataCard,
        ChartCards,
        Form,
        NSpace,
        NSpin
    },
    computed: {
        ...mapState(['selectedLngLat', 'address', 'showForm', 'areaData', 'failedReport'])
    },
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        this.loading = !this.areaData.population
    }
}
</script>


<style scoped>
.map-container {
    text-align: start;
}

.h2-container {
    width: 100%;
    padding: 50px 26px 10px;
    margin: 0;
}

.card-container {
    width: 100%;
    padding: 26px;
    margin: 0 auto;
    overflow-x: hidden;
}

.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: auto;
    background: radial-gradient(farthest-side, #c1a549 94%, #0000) top/9px 9px no-repeat,
        conic-gradient(#0000 30%, #c1a549);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
    animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
    100% {
        transform: rotate(1turn);
    }
}

.warning-msg {
    margin: 5px 0 5px 80px;
}

@media (min-width: 834px) {
    .h2-container {
        padding: 50px 40px 10px;
    }

    .card-container {
        padding: 40px;
    }
}


@media (min-width: 1280px) {
    .h2-container {
        padding: 50px 68px 10px;
    }

    .card-container {
        padding: 68px;
    }
}
</style>